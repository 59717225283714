import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(e) {
    e.stopPropagation();
    if ('a' == e.target.tagName.toLowerCase()) return; // don't close entry if user clicked on link inside entry
    let target = e.currentTarget.closest('.schedule__entry'),
        close = target.classList.contains('active'),
        active = document.querySelector('.schedule__entry.active');
    if (active) active.classList.remove('active');
    if (!close) target.classList.add('active');
  }

  connect() {
    let today = new Date(),
        lang = document.getElementsByTagName('html')[0].lang;

    document.querySelectorAll('.schedule__entry').forEach(entry => {
      let time = entry.querySelector(':scope [data-kind="entry"][data-datetime]'),
          startDate = new Date(time.dataset.datetime),
          endDate = new Date(time.dataset.endtime);
      time.innerHTML = du.formatTime(startDate);
      entry.dataset.selItem = `_${startDate.getMonth() + 1}-${startDate.getDate()}`;
      if (startDate <= today && today < endDate) {
        time.parentNode.nextElementSibling.classList.add('active');
      }
      let encores = entry.querySelectorAll(':scope [data-kind="encore"][data-datetime]');
      for (let i = 0; i < encores.length; i++) {
        encores[i].innerHTML = du.formatDateTime(new Date(encores[i].dataset.datetime), 'informal', lang);
      }
    });

    let activeDay = document.querySelector('[data-sel-class="day"] [data-sel-control].active');
    if (activeDay) {
      document.querySelectorAll(`[data-sel-item="${activeDay.dataset.selItem}"]`).forEach(item => item.classList.add('current'));
    } else {
      let dayClass = `_${today.getMonth() + 1}-${today.getDate()}`;
      document.querySelectorAll(`[data-sel-item="${dayClass}"]`).forEach(item => item.classList.add('current'));
      document.querySelectorAll(`[data-sel-control][data-sel-item="${dayClass}"]`).forEach(item => item.classList.add('active'));
    }

    document.querySelectorAll('.schedule__link').forEach(link => link.onclick = this.toggle);

    let specials = document.querySelector('.schedule__container.--theme--special');
    if (specials) {
      let dayDivider = specials.querySelector(':scope #day-divider').innerHTML,
          lang = document.getElementsByTagName('html')[0].lang,
          prevTime, dups;
      specials.querySelectorAll(':scope .schedule__entry').forEach(entry => {
        let startTime = new Date(entry.querySelector(':scope .schedule__time').dataset.datetime);
        if (!prevTime || startTime.getDate() != prevTime.getDate()) {
          entry.before(dom.genElement(dom.replaceAll(dayDivider, {
            weekday: du.dayNames[lang][startTime.getDay()].slice(0,3),
            date: du.formatDate(startTime, 'widget', lang)
          })));
          prevTime = startTime;
          dups = {};
        }
        if (dups[entry.dataset.titleCode]) entry.style.display = 'none';
        dups[entry.dataset.titleCode] = true;
      });
    }
  }

}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const selected = $('#donation_form_form_type').children('option:selected').val()
    const target = selected == "5stones" ? ".stones" : "." + selected
    $('.extended').hide();
    $(target).show();
  }

  change(event) {
    const selected = event.target.selectedOptions[0].value
    const target = selected == "5stones" ? ".stones" : "." + selected
    $('.extended').hide();
    $(target).show();
  }
}
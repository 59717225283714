import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  t = {
    en: {
      massTitle: 'Daily Mass',
      ytWatchMsg: 'Watch on YouTube',
      massPrefix: 'ENMASS',
      noResultsMsg: 'Your search produced no results. Please try again.'
    },
    es: {
      massTitle: 'Misa Diaria',
      ytWatchMsg: 'Ver en YouTube',
      massPrefix: 'SPMASS',
      noResultsMsg: 'Su búsqueda no obtuvo resultados. Por favor inténtela de nuevo.'
    }
  };

  metaPage(pageNo) {
    return Math.trunc(pageNo / 10) * 10;
  }
  
  buildArrows(pageNo, pages) {
    let arrows = dom.genElement('<div class="audio__arrow"></div>');
    if (pages < 2) return arrows;
    if (this.metaPage(pageNo - 1) > 0) {
      arrows.append(dom.genElement(`<div class="audio__arrow --theme--nav audio__show-link --theme--start" data-page-no="1"></div>`));
      arrows.append(dom.genElement(`<div class="audio__arrow --theme--nav audio__show-link --theme--prev" data-page-no="${this.metaPage(pageNo - 11) + 10}"></div>`));
    }
    for (let i = this.metaPage(pageNo - 1); i < this.metaPage(pageNo + 9) && i < pages; i++) {
      arrows.append(dom.genElement(`<div class="audio__arrow --theme--nav ${i + 1 == pageNo ? 'current' : 'audio__show-link'}" data-page-no="${i + 1}">${i + 1}</div>`));
    }
    if (pageNo - 1 < this.metaPage(pages - 1)) {
      arrows.append(dom.genElement(`<div class="audio__arrow --theme--nav audio__show-link --theme--next" data-page-no="${this.metaPage(pageNo + 9) + 1}"></div>`));
      arrows.append(dom.genElement(`<div class="audio__arrow --theme--nav audio__show-link --theme--end" data-page-no="${this.metaPage(pages) + 1}"></div>`));
    }
    return arrows;
  }
  
  searchShows(e) {
    e.preventDefault();
    let search = this.showsQuery.value != '' ? `/${encodeURIComponent(this.showsQuery.value)}` : '';
    fetch(`/api/audio-programs/${this.lang}${search}`)
    .then(response => response.json())
    .then(data => {
      let container = this.showsListing;
      container.innerHTML = '';
      if (data.length < 1) {
        container.append(dom.genElement(`<div class="no-results">${this.t[this.lang].noResultsMsg}</div>`));
        return;
      }
      data.forEach((show) => {
        let div = dom.genElement(`<div class="audio__show-entry" data-prefix="${show.prefix}" data-pages="${show.pages}"></div>`);
        div.append(dom.genElement(`<div class="audio__show-name audio__show-link" data-page-no="1">${show.name}</div>`));
        div.append(dom.genElement(`<div class="audio__show-synopsis">${show.synopsis}</div>`));
        div.append(dom.genElement(`<div class="audio__episodes-listing"></div>`));
        container.append(div);
      });
    });
  }

  searchHomily(e) {
    e.preventDefault();
    let today = new Date(),
        from = this.element.homily_begin.value || '2015-01-01',
        to = this.element.homily_end.value || du.formatDate(today, 'simple'),
        search = this.element.homily_query.value != '' ? `/${encodeURIComponent(this.element.homily_query.value)}` : '';
    fetch(`/api/homilies/${from}/${to}/${this.lang}${search}`)
    .then(response => response.json())
    .then(data => {
      let container = this.showsListing,
          subContainer = dom.genElement(`<div class="audio__episodes-listing"></div>`);
      container.innerHTML = '';
      if (data.length < 1) {
        container.append(dom.genElement(`<div class="no-results">${this.t[this.lang].noResultsMsg}</div>`));
        return;
      }
      container.append(subContainer);
      data.forEach(homily => {
        let div = dom.genElement(`<div class="audio__episode"></div>`),
            subDiv = dom.genElement(`<div class="audio__episode-inner"></div>`);
        div.append(subDiv);
        subDiv.append(
          dom.genElement(`<div class="audio__episode-name" data-modal-player="audio" data-media-id="${this.t[this.lang].massPrefix}/${homily.date.replace(/\-/g, '')}.mp3"><span class="audio__play-button"></span>${this.t[this.lang].massTitle} - ${homily.date} - ${homily.name}</div>`)
        );
        subDiv.append(dom.genElement(`<div class="audio__episode-synopsis">${homily.title}</div>`));
        if (homily.ytid && homily.ytid != '') subDiv.append(dom.genElement(`<a class="audio__youtube-link btn --theme--1 --size--sm" href="//www.youtube.com/watch?v=${homily.ytid}" target="_blank">${this.t[this.lang].ytWatchMsg}</a>`));
        subContainer.append(div);
      });
    });
    return false;

  }

  searchEpisodes(e) {
    let target = e.target;
    if (!target.classList.contains('audio__show-link')) return;
    e.stopPropagation();
    let entry = target.closest('.audio__show-entry'),
        container = entry.querySelector('.audio__episodes-listing');
    if (entry.dataset.loaded && target.classList.contains('audio__show-name')) {
      container.style.display = 'none' == container.style.display ? '' : 'none';
      return;
    }
    let pageNo = parseInt(target.dataset.pageNo),
        search = this.showsQuery.value != '' ? `/${encodeURIComponent(this.showsQuery.value)}` : '';
    fetch(`/api/audio-episodes/${this.lang}/${entry.dataset.prefix}/${pageNo}${search}`)
    .then(response => response.json())
    .then(data => {
      let arrowsContainer = dom.genElement(`<div class="audio__arrow-container"></div>`);
      container.innerHTML = '';
      container.append(arrowsContainer);
      entry.dataset.loaded = true;
      arrowsContainer.append(this.buildArrows(pageNo, entry.dataset.pages));
      data.forEach(episode => {
        let div = dom.genElement(`<div class="audio__episode"></div>`),
            subDiv = dom.genElement(`<div class="audio__episode-inner"></div>`);
        div.append(subDiv);
        subDiv.append(dom.genElement(`<div class="audio__episode-name" data-modal-player="audio" data-media-id="${entry.dataset.prefix}/${episode.code}.mp3"><span class="audio__play-button"></span>${episode.name}</div>`));
        subDiv.append(dom.genElement(`<div class="audio__episode-synopsis">${episode.synopsis} (Ep. ${parseInt(episode.code.substr(3))})</div>`));
        if (episode.ytid) subDiv.append(dom.genElement(`<a class="audio__youtube-link btn --theme--1 --size--sm" href="//www.youtube.com/watch?v=${episode.ytid}" target="_blank">${this.t[this.lang].ytWatchMsg}</a>`));
        container.append(div);
      });
      container.append(arrowsContainer.cloneNode(true));
      entry.scrollIntoView();
    });
  }

  connect() {
    this.lang = document.getElementsByTagName('html')[0].lang;
    this.showsListing = document.getElementById('shows-listing');
    this.showsListing.onclick = e => this.searchEpisodes(e);
    this.showsQuery = document.getElementById('shows-query');
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  defs = {
    video: {
      baseUrl: '/api/player/',
      player: '<div style="position: relative; display: block; max-width: 100%;"><div style="padding-top: 56.25%;"><iframe src="{src}" allowfullscreen webkitallowfullscreen mozallowfullscreen allow="encrypted-media" style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe></div></div>'
    },
    playlist: {
      baseUrl: '/api/playlist-player/',
      player: '<div style="position: relative; display: block; max-width: 100%;"><div style="padding-top: 56.25%;"><iframe src="{src}" allowfullscreen webkitallowfullscreen mozallowfullscreen allow="encrypted-media" style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe></div></div>'
    },
    audio: {
      baseUrl: '//i.listen.ewtn.com/',
      player: '<audio controls autoplay><source src="{src}"></audio>'
    },
    vurl: {
      baseUrl: '',
      player: '<video controls autoplay width="100%" height="100%"><source src="{src}"></video>'
    },
    vimeo: {
      baseUrl: 'https://player.vimeo.com/video/',
      player: '<iframe style="height: 360px; width: 480px;" src="{src}?autoplay=1" id="media_vimeo_65804463_1_iframe" class="vimeo-player" type="text/html" frameborder="0" height="360" width="480"></iframe>'
    },
    youtube: {
      baseUrl: 'https://www.youtube.com/embed/',
      player: '<iframe width="1016" height="762" src="{src}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    }
  }

  buildModal(type, mediaId) {
    let modal = dom.genElement(`<div class="modal-player modal-player--theme--main"></div>`),
        container = dom.genElement(`<div class="modal-player__container"></div>`),
        closeButtonContainer = dom.genElement(`<span class="modal-player__close-button"></span>`);
    closeButtonContainer.append(dom.genElement(`<span class="modal-player__close-button-x"></span>`));
    modal.append(closeButtonContainer);
    container.append(dom.genElement(this.defs[type].player.replace('{src}', this.defs[type].baseUrl + mediaId)));
    modal.append(container);
    return modal;
  }

  showModal(e) {
    let target = e.target.closest('[data-modal-player]');
    if (!target) return;
    e.stopPropagation();
    let modal = this.buildModal(target.dataset.modalPlayer, target.dataset.mediaId),
        container = modal.querySelector(':scope .modal-player__container');
    this.element.append(modal);
    container.style.marginTop = `${(document.documentElement.clientHeight - parseFloat(container.style.height)) / 2}px`;
  }

  hideModal(e) {
    if (!e.target.closest('.modal-player') || e.target.tagName.toLowerCase() == 'audio') return; // Safari audio element propagates click events; don't destroy modal if user/player interaction
    e.stopPropagation();
    this.element.querySelector(':scope > .modal-player').remove();
  }
  
  connect() {
    this.element.addEventListener('click', (e) => this.showModal(e));
    this.element.addEventListener('click', (e) => this.hideModal(e));
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let lang = document.getElementsByTagName('html')['0'].lang,
        datetime = new Date(this.element.dataset.datetime);
    switch (this.element.dataset.format) {
    case 'datetime':
      this.element.innerHTML = du.formatDateTime(datetime, 'informal', lang);
      break;
    case 'weektime':
      this.element.innerHTML = du.formatWeekTime(datetime, lang);
      break;
    case 'yeardatetime':
      this.element.innerHTML = du.formatDateTime(datetime, 'simple', lang);
      break;
    case 'weekdays':
      this.element.innerHTML = du.formatWeekDay(datetime, 'plural', lang);
      break;
    default:
      this.element.innerHTML = du.formatTime(datetime);
      break;
    }
  }
}

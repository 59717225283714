import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  minLength = 3;

  genItems(data) {
    return data.map(it => { return `<div class="ui-autocomplete-item" data-id="${it.id}">${it.value}</div>` }).join('');
  }

  positionWidget() {
    let rect = this.element.getBoundingClientRect();
    this.widget.style.left = `${rect.left}px`;
    this.widget.style.top = rect.top < window.innerHeight / 2 ?
      `${rect.bottom + document.documentElement.scrollTop + 2}px` :
      `${rect.top + document.documentElement.scrollTop - this.widget.scrollHeight -2}px`;
  }

  installHandlers() {
    window.onkeydown = (e) => { if (e.key == 'Escape' || e.key == 'Tab') this.hide(); } 
    window.onclick = (e) => { if (e.target != this.element) this.hide() };
    this.widget.onclick = (e) => {
      e.stopPropagation();
      this.element.value = e.target.innerHTML;
      this.target.value = e.target.dataset.id;
      this.element.focus();
      this.hide();
    }
  }

  uninstallHandlers() {
    window.onkeydown = window.onclick = this.widget.onclick = null;
  }

  show() {
    this.position = null;
    this.positionWidget();
    this.installHandlers();  
  }

  hide() {
    this.widget.innerHTML = '';
    this.widget.style.top = '-1000px';
    this.uninstallHandlers();
  }

  pick() {
    this.widget.childNodes.forEach((div) => div.classList.remove('ui-autocomplete-picked'));
    this.widget.childNodes[this.position].classList.add('ui-autocomplete-picked');
    this.element.value = this.widget.childNodes[this.position].innerHTML;
    this.target.value = this.widget.childNodes[this.position].dataset.id;
  }

  connect() {
    let form = this.element.parentElement;
    this.target = form.querySelector('input[name="target"]');
    form.onsubmit = (e) => { if (!this.target.value) e.preventDefault(); }
    this.widget = document.getElementById('ui-autocomplete-widget');
    if (!this.widget) {
      this.widget = document.createElement('div');
      this.widget.id = 'ui-autocomplete-widget';
      this.widget.classList.add('ui-autocomplete');
      this.widget.style.position = 'absolute';
      this.widget.style.top = '-1000px'; // render off-screen to get initial height
      document.body.appendChild(this.widget);
    }
    this.element.onkeyup = (e) => {
      switch(e.key) {
        case 'Escape':
        case 'Enter': this.hide(); return;
        case 'ArrowLeft':
        case 'ArrowRight': return;
        case 'ArrowUp':
          if (this.position == null) this.position = this.widget.childNodes.length - 1;
          else this.position = (this.position + this.widget.childNodes.length - 1) % this.widget.childNodes.length;
          this.pick(); return;
        case 'ArrowDown':
          if (this.position == null) this.position = 0;
          else this.position = (this.position + 1) % this.widget.childNodes.length;
          this.pick(); return;
      }
      if (this.element.value.length < this.minLength) { this.hide(); return; }
      let url = `${this.element.dataset.source}/${this.element.value}`;
      if (this.element.dataset.except != '') url = `${url}/${this.element.dataset.except}`
      fetch(url)
      .then(response => response.json())
      .then(data => {
        this.widget.innerHTML = this.genItems(data);
        this.show();
      });
    }
  }
}
// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import DateUtil from './src/date-util'
import DomUtil from './src/dom-util'

window.du = new DateUtil();
window.dom = new DomUtil();

document.addEventListener('turbo:load', function() {
  dataLayer.push({ event: 'pageView', virtualUrl: window.location.href }); // so GTM tags can fire on link navigation

  // undocumented fix for ShareThis turbolinks incompatibility: https://github.com/reed/turbolinks-compatibility/issues/79
  if (typeof __sharethis__ != 'undefined' && __sharethis__.config) {
    __sharethis__.href = document.location.href;
    __sharethis__.init(__sharethis__.config);
  }

  let hs = document.querySelectorAll('[data-hubspot-form-id]');
  for (let i = 0; i < hs.length; i++) {
    hbspt.forms.create({
      portalId: "4395239",
      formId: hs[i].dataset.hubspotFormId,
      target: `#${hs[i].id}`
    });
  }
  // Marin - RaiseDonors tracking
  let div = document.getElementById('rd-marin-tracker');
  if (new URLSearchParams(window.location.search).has('mkwid') && !document.getElementById('raiseDonorsEmbedForm')) {
    div.innerHTML = div.querySelector(':scope script').innerHTML.replace('{params}', window.location.search.substring(1));
  } else {
    div.innerHTML = '';
  }
});

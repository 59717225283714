import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let gtags = document.getElementsByTagName('g');
    for (let i = 0; i < gtags.length; i++) {
      gtags[i].onmouseenter = e => {
        let box = document.getElementById('info-box');
        box.innerHTML = e.target.dataset.info;
        box.style.display = 'block';
      };
      gtags[i].onmouseleave = e => {
        let box = document.getElementById('info-box');
        box.style.display = 'none';
      };
    }

    let mapContainer = document.querySelector('.map__container');
    mapContainer.onmousemove = e => {
      let box = document.getElementById('info-box');
      box.style.top = `${e.pageY - box.clientHeight - 20}px`;
      box.style.left = `${e.pageX - box.clientWidth / 2}px`;
    };
    mapContainer.onclick = e => {
      e.stopPropagation();
      let sidebar = document.querySelector('.map__container .map__listing'),
          state = states[e.target.closest('g').id];
      sidebar.replaceChildren();
      sidebar.append(dom.genElement(`<h1>${state.name}</h1>`));
      state.stations.forEach(station => {
        var entry = dom.genElement(`<div class="entry-container"></div>`),
            a = dom.genElement(`<a href="${station.station_link || '#'}" target="_blank"></a>`),
            div = dom.genElement(`<div class="affiliate-text"></div>`);
        div.append(dom.genElement(`<div>${station.frequency}</div>`));
        div.append(dom.genElement(`<div>${station.call_sign}</div>`));
        div.append(dom.genElement(`<div><strong>${station.city}</strong></div>`));
        div.append(dom.genElement(`<div>${station.phone_number}</div>`));
        a.append(dom.genElement(`<div class="affiliate-image"><img src="https://www.ewtn.com/img/radio/stations/${station.image_name}" alt="${station.call_sign}"></div>`));
        a.append(div);
        entry.append(a);
        sidebar.append(entry);
      });
    }

    Object.keys(states).forEach(state => document.getElementById(state).dataset.info = `<div>${states[state].name} (${states[state].stations.length})</div>`);
  }

}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item'];

  toggle() {
    this.itemTargets.forEach(item => {
      if (item.classList.contains('--invisible'))
        item.classList.remove('--invisible');
      else
        item.classList.add('--invisible');
    });
  }
}
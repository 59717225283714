import { Controller } from "@hotwired/stimulus"
import Fader from '../src/fader'

export default class extends Controller {

  connect() {
    this.dotContainer = document.getElementById(this.element.dataset.dotContainer);
    this.fetchFeed();
  }

  fetchFeed() {
    let path = location.pathname.endsWith('/') && location.pathname != '/' ? location.pathname.substring(0, location.pathname.length - 1) : location.pathname;
    fetch(`/api/banner-slots/${encodeURIComponent(path)}/${encodeURIComponent(this.element.dataset.bannerSlot)}`)
    .then(response => response.json())
    .then(data => this.handleSlot(data));
  }

  handleSlot(data) {
    this.element.classList.contains('_inserter') ? this.buildInserter(data) : this.buildSlot(data);
  }

  // Pick n distinct numbers between 0 and m - 1; based on Fisher-Yates shuffle
  pickNFromM(n, m) {
    let candidates = Array.from(Array(m).keys()), i = m, target, tmp;
    while (i >= m - n && i > 0) {
      target = Math.floor(Math.random() * i--),
      tmp = candidates[i];
      candidates[i] = candidates[target];
      candidates[target] = tmp;
    }
    return candidates.slice(m - n, m);
  }

  shuffle(list) {
    for (let i = list.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
  }

  selectBanners(data) {
    let banners = [], overrides = {}, candidates = [], selected = {};
    for (let i = 0; i < data.banners.length; i++) {
      let banner = data.banners[i];
      if (banner.override) {
        overrides[banner.override - 1] = banner;
      } else {
        for (let j = 0; j < banner.weight; j++) {
          candidates.push(banner);
        }
      }
    }
    this.shuffle(candidates);
    let j = 0;
    for (let i = 0; i < data.count; i++) {
      if (overrides[i]) {
        banners.push(overrides[i]);
      } else {
        while (j < candidates.length && selected[candidates[j].id]) j++;
        if (j >= candidates.length) continue;
        banners.push(candidates[j]);
        selected[candidates[j].id] = true;
      }
    }
    return banners;
  }

  buildSlot(data) {
    if (data.banners.length == 0) {
      this.element.remove();
      return;
    }
    let bannerTemplate = this.element.querySelector(':scope script'),
        banners = this.selectBanners(data);
    for (let i = 0; i < banners.length; i++) {
      let banner = dom.genElement(dom.replaceAll(bannerTemplate.innerHTML, banners[i]));
      if (0 == i) banner.classList.add('active');
      if ('' == banners[i].link_url) banner.querySelector(':scope a').removeAttribute('href');
      this.element.append(banner);
    }
    if (this.element.classList.contains('_fader')) {
      let dotTemplate = this.dotContainer.querySelector(':scope script');
      for (let i = 0; i < banners.length; i++) {
        let dot = dom.genElement(dom.replaceAll(dotTemplate.innerHTML, { dot_index: i }));
        if (0 == i) dot.querySelector(':scope ._fader-dot').classList.add('active');
        this.dotContainer.append(dot);
      }
      this.fader = new Fader(this.element, this.dotContainer, this.element.dataset.faderDuration || 7000);
    }
  }

  buildInserter(data) {
    let items = this.element.querySelectorAll(':scope ._inserter-item');
    if (items.length == 0) return;
    let lastItem = items[items.length - 1];
    lastItem.style.display = ''; // in case no banner to insert and cached version is hidden
    if (data.banners.length == 0) return;
    let bannerTemplate = this.element.querySelector(':scope script'),
        banners = this.selectBanners(data),
        banner = dom.genElement(dom.replaceAll(bannerTemplate.innerHTML, banners[0]));
    items[Math.floor(Math.random() * items.length)].before(banner);
    lastItem.style.display = 'none';
  }

  disconnect() {
    if (typeof this.fader !== 'undefined') {
      this.fader.stop();
      let dotItems = this.dotContainer.querySelectorAll(':scope ._banner-item');
      for (let i = 0; i < dotItems.length; i++) dotItems[i].remove();
    }
    let items = this.element.querySelectorAll(':scope ._banner-item');
    for (let i = 0; i < items.length; i++) items[i].remove();
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu'];

  toggle(e) {
    e.stopPropagation();
    this.menuTarget.classList.toggle('active');
    this.menuTarget.style.height = this.menuTarget.classList.contains('active') ? `${this.menuTarget.scrollHeight}px` : 0;
  }
}
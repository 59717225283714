class DomUtil {

  genElement(html) {
    let template = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content.children[0];
  }
  
  offset(el) {
    let box = el.getBoundingClientRect(),
        docElem = document.documentElement;
    return {
      top: box.top + window.scrollY - docElem.clientTop,
      left: box.left + window.scrollX - docElem.clientLeft
    };
  }

  // very basic template renderer - variable names must be enclosed in {}
  // note: for simplicity, {} cannot appear in the rendered output (no escaping possible)
  replaceAll(s, o) {
    var strings = s.split(/[{}]/), out = [];
    for (var i = 0; i < strings.length; i++) {
      out.push(i % 2 == 0 ? strings[i] : o[strings[i]]);
    }
    return out.join('');
  }

}

export default DomUtil;


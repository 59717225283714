import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  msgs = {
    en: {
      channel: 'Channel',
      error: 'Unexpected error retrieving channel data',
      badzip: 'No information was found for postal code',
      retry: 'Please check the code and try again'
    },
    es: {
      channel: 'Canal',
      error: 'Recibimos un error al buscar los datos',
      badzip: 'No encontramos ninguna información para el código postal',
      retry: 'Favor de corregir el código e intentar de nuevo'
    }
  }

  connect() {
    this.element.onsubmit = () => this.getChannels();
    document.getElementById("zccCountry").onchange = () => this.clearZip();
    this.lang = document.getElementsByTagName('html')[0].lang;
  }

  getChannels() {
    let country = document.getElementById("zccCountry").value;
    let zip = document.getElementById("zccZip").value;

    this.disableSubmit();
    this.processChannels(country, zip);
    return false;
  }

  processChannels(country, zip) {
    fetch(`https://channel-finder.ewtnos.com/cf/${country}/${zip}`)
    .then(response => response.json())
    .then(data => this.channelSuccess(data))
    .finally(() => this.enableSubmit())
    .catch(() => this.channelFail());
  }

  channelFail() {
    this.displayError(this.msgs[this.lang].error);
  }

  channelSuccess(data) {
    let name = document.querySelector("#zccCountry option[selected]").innerHTML;
    let zip = document.getElementById("zccZip").value;

    document.getElementById("channelfinder").innerHTML = '';
    this.clearError();

    if (data.ERROR) {
      this.displayError(`${this.msgs[this.lang].badzip} ${zip}.  ${this.msgs[this.lang].retry}.`);
    } else {
      let ziptext = name + ' - ' + zip;
      let divHead = dom.genElement(`<div class="zcc-head">${ziptext}</div>`);
      document.getElementById('channelfinder').append(divHead);
      this.getProviderInfo(data);
    }
  }

  getProviderInfo(data) {
    let channelContainer = document.getElementById('channelfinder');
    for (let t = 0; t < data.length; t++) {
      if (data[t].providers.length == 0) continue;
      let h2ProviderType = dom.genElement(`<h2 class="zcc-provider-type">${data[t].type}</h2>`);
      channelContainer.append(h2ProviderType);
      let divProvider = dom.genElement(`<div class="zcc-provider"></div>`);
      for (let p = 0; p < data[t].providers.length; p++) {
        let spanProviderName = dom.genElement(`<span class="zcc-provider-name">${data[t].providers[p].name}</span>`);
        let spanProviderInfo = dom.genElement(`<span class="zcc-providers-info"></span>`);
        spanProviderInfo.append(spanProviderName);
        let divProviders = dom.genElement(`<div class="zcc-providers"></div>`);
        divProviders.append(spanProviderInfo);
        for (let s = 0; s < data[t].providers[p].stations.length; s++) {
          let spanStation = dom.genElement(`<span class="zcc-station">${data[t].providers[p].stations[s].name}</span>`);
          let spanStationChannel = dom.genElement(`<span class="zcc-station-channel"></span>`);
          spanStationChannel.append(spanStation);
          spanStationChannel.append(dom.genElement(`<span class="zcc-channel">${this.msgs[this.lang].channel} ${data[t].providers[p].stations[s].channels.join(' / ')}</span>`));
          let divStationChannelList = dom.genElement(`<div class="zcc-station-channel-list"></div>`);
          divStationChannelList.append(spanStationChannel);
          divProviders.append(divStationChannelList);
        }
        divProvider.append(divProviders);
        channelContainer.append(divProvider);
      }
    }
  }

  enableSubmit() {
    document.getElementById("zccSubmit").style.display = '';
    document.getElementsByClassName("zcc-loading")[0].style.display = 'none';
  }

  disableSubmit() {
    document.getElementById("zccSubmit").style.display = 'none';
    document.getElementsByClassName("zcc-loading")[0].style.display = '';
    this.clearError();
  }

  clearZip() {
    document.getElementById("zccZip").value = '';
    this.clearError();
  }

  clearError() {
    document.getElementById("errorContainer").innerHTML = '';
  }

  displayError(msg) {
    document.getElementById('errorContainer').append(dom.genElement(`<div id="zccFormText" class="zcc-error">${msg}</div>`));
  }

}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['kind', 'prefix'];

  setPrefixPlaceholder() {
    if ('manual' == this.kindTarget.value) {
      this.prefixTarget.style.display = 'none';
      let curPrefix = this.prefixTarget.querySelector(':scope input');
      if (!curPrefix.value.match(/^000-[0-9]+$/))
        curPrefix.value = '';
    } else {
      this.prefixTarget.style.display = '';
    }
  }

  connect() {
    this.setPrefixPlaceholder();
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['sort', 'featured', 'cat', 'author', 'language'];

  navigate() {
    let sort = this.sortTarget.value,
        featured = this.featuredTarget.checked,
        cats = this.catTargets.filter(e => e.checked).map(e => e.name).join(','),
        authors = this.authorTargets.filter(e => e.checked).map(e => e.name).join(','),
        languages = this.languageTargets.filter(e => e.checked).map(e => e.name).join(',');
    Turbo.visit(`/publishing/ourbooks?sort=${sort}&featured=${featured}&cats=${cats}&authors=${authors}&langs=${languages}`);
  }
}

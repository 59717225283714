import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'success', 'error'];

  msgs = {
    en: {
      recaptcha: 'The reCAPTCHA is required',
      error: 'An error occurred'
    },
    es: {
      recaptcha: 'Es necesario completar el reCAPTCHA',
      error: 'Ocurrió un error'
    }
  }

  connect() {
    this.lang = document.getElementsByTagName('html')[0].lang;

    this.formTarget.querySelector(':scope form').onsubmit = e => {
      if (!grecaptcha.getResponse()) {
        this.error(this.msgs[this.lang].recaptcha);
        return false;
      }
      this.formTarget.style.display = 'none';
    };

    this.formTarget.querySelector(':scope form').addEventListener('turbo:submit-end', e => {
      if (e.detail.success) {
        this.successTarget.style.display = 'block';
        this.errorTarget.style.display = 'none';
      } else {
        this.error(this.msgs[this.lang].error, 'system')
      }
      grecaptcha.reset();
    });

    let resubmit = this.successTarget.querySelector(':scope ._resubmit');
    if (resubmit) {
      resubmit.onclick = e => {
        this.successTarget.style.display = 'none';
        this.errorTarget.style.display = 'none';
        this.formTarget.style.display = 'block';
      };
    }
  }

  error(message, system) {
    this.errorTarget.querySelector(':scope ._error-message').innerHTML = message;
    this.errorTarget.querySelector(':scope ._error-report').style.display = system ? 'block' : 'none';
    this.errorTarget.style.display = 'block';
  }
}
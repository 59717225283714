import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  show(e) {
    e.stopPropagation();
    this.modal.style.display = 'block';
    this.container.style.marginTop = `${(document.documentElement.clientHeight - this.container.clientHeight) / 2}px`;
  }

  connect() {
    this.modal = this.element.parentNode.nextElementSibling;
    this.container = this.modal.querySelector(':scope .pick-details__container');
    this.modal.onclick = e => { e.stopPropagation(); this.modal.style.display = 'none'; };
    this.container.onclick = e => e.stopPropagation();
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['time', 'title'];

  handleAudio() {
    fetch(`/Live/ewtnplayer/scheds/${this.element.dataset.sat}.json`)
    .then(response => response.json())
    .then(data => {
      let now = new Date(), i = 0;
      while (i < data.length) { if (new Date(data[i].end_date) > now) break; i++; }
      for (let j = 0; j < 2 && i < data.length; j++, i++) {
        this.titleTargets[j].innerHTML = data[i].program_name;
        this.timeTargets[j].innerHTML = du.formatTime(new Date(data[i].start_date));
      }
    });
  }

  handleVideo() {
    fetch(`/api/now-showing/${this.element.dataset.sat}`)
    .then(response => response.json())
    .then(data => {
      let now = new Date(), title, start, j = 0;
      while (j < data.length && now >= new Date(data[j].end_date)) j++;
      for (let i = 0; i < this.titleTargets.length && j < data.length; i++, j++) {
        this.titleTargets[i].innerHTML = data[j].program_name;
        this.timeTargets[i].innerHTML = du.formatTime(new Date(data[j].start_date));
      }
    });
  }

  connect() {
    if (this.element.dataset.format == 'audio')
      this.handleAudio();
    else
      this.handleVideo();
  }
}

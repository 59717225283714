class Fader {

  scheduleFade() {
    this.autoFadeTimer = setTimeout(() => {
      this.handleNext();
      this.scheduleFade();
    }, this.autoFadeInterval);
  }

  constructor(container, dotContainer, autoFadeInterval) {
    this.items = container.querySelectorAll(':scope ._fader-item');
    this.dotContainer = dotContainer;
    this.dots = dotContainer.querySelectorAll(':scope ._fader-dot');
    this.activeItem = 0;
    this.numItems = this.items.length;
    for (let i = 0; i < this.dots.length; i++) { this.dots[i].onclick = e => this.handleDot(e); }
    this.autoFadeInterval = autoFadeInterval;
    if (autoFadeInterval) this.scheduleFade();
  }

  handleDot(e) {
    e.stopPropagation();
    let from = this.activeItem,
        to = e.target.dataset.index;
    if (from == to) return;
    this.fade(from, to);
  }

  handleNext() {
    if (this.numItems < 2) return;
    var from = this.activeItem,
        to = (from + 1) % this.numItems;
    this.fade(from, to);
  }

  fade(from, to) {
    if (this.autoFadeInterval) this.stop();
    this.dots[from].classList.remove('active');
    this.dots[to].classList.add('active');
    this.items[from].classList.remove('active');
    this.items[to].classList.add('active');
    this.activeItem = to;
  }

  stop() {
    clearTimeout(this.autoFadeTimer);
  }

}

export default Fader;

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  refreshControls(selector, item) {
    if (selector.dataset.selType == 'scroll') {
      let values = selector.dataset.selValues.split(',');
      selector.querySelectorAll(':scope [data-sel-control]').forEach(el => el.classList.remove('hidden'));
      if (item == values[0]) selector.querySelectorAll(':scope [data-sel-control="prev"]').forEach(el => el.classList.add('hidden'));
      else if (item == values[values.length - 1]) selector.querySelectorAll(':scope [data-sel-control="next"]').forEach(el => el.classList.add('hidden'));
    } else {
      selector.querySelectorAll(':scope [data-sel-control].active').forEach(el => el.classList.remove('active'));
      selector.querySelectorAll(':scope [data-sel-control][data-sel-item="' + item + '"]').forEach(el => el.classList.add('active'));
    }
  }

  change(e) {
    e.stopPropagation();
    let control = e.currentTarget,
      selector = control.closest('[data-sel-type]'),
      selectorClass = selector.dataset.selClass,
      selectorType = selector.dataset.selType,
      item = control.dataset.selItem;
    if ('dropdown' == selectorType) {
      if (selector.classList.contains('hovering')) {
        selector.classList.remove('hovering'); // desktop: drop-down open, close it and process selection
      } else {
        selector.classList.add('hovering'); // mobile: drop-down closed, open it
        return;
      }
    }
    if (control.classList.contains('active')) return;
    if (selector.dataset.selUrl) {
      this.refreshControls(selector, item);
      window.location = selector.dataset.selUrl + '/' + item;
    } else {
      if ('scroll' == selectorType) {
        let values = selector.dataset.selValues.split(','),
            currentItem = document.querySelector('[data-sel-item].current').dataset.selItem.toString(),
            currentIndex = values.indexOf(currentItem),
            nextIndex = currentIndex + (control.dataset.selControl == 'prev' ? -1 : 1);
        document.querySelectorAll('[data-sel-item]').forEach(el => el.classList.remove('current'));
        item = values[nextIndex];
        document.querySelectorAll('[data-sel-item="' + item + '"]').forEach(el => el.classList.add('current'));
      } else {
        document.querySelectorAll('[data-sel-item]').forEach(el => el.classList.remove('current'));
        let selected = '__all__' == item ? '[data-sel-item]' : '[data-sel-item="' + item + '"]';
        document.querySelectorAll(selected).forEach(el => el.classList.add('current'));
      }
      document.querySelectorAll('[data-sel-class="' + selectorClass + '"]').forEach(sel => this.refreshControls(sel, item));
    }
  }

  connect() {
    if (!(window.navigator.maxTouchPoints || 'ontouchstart' in document)) {
      document.querySelectorAll('[data-sel-type="dropdown"]').forEach(dropdown => {
        dropdown.onmouseenter = e => e.currentTarget.classList.add('hovering');
        dropdown.onmouseleave = e => e.currentTarget.classList.remove('hovering');
      });
    }
    document.querySelectorAll('[data-sel-control]').forEach(control => control.onclick = e => this.change(e));
  }

}
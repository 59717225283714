import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['visibility'];

  visibility(e) {
    this.visibilityTarget.checked = e.target.value.match(/^(leaf|blocks)$/);
  }

  connect() {
    console.log('documents controller here');
  }
}
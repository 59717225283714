import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  getSlots(start, stop) {
    let slotOffset = (stop.getTimezoneOffset() - start.getTimezoneOffset()) / 30;
    return {
      start: { day: start.getDay(), slot: start.getHours() * 2 + Math.floor(start.getMinutes() / 30) },
      stop: { day: stop.getDay(), slot: stop.getHours() * 2 + Math.ceil(stop.getMinutes() / 30) + slotOffset}
    };
  }

  buildHeaderRow(tz, lang, startDate) {
    let date = new Date(startDate.getTime()),
        tr = dom.genElement('<tr>');
    tr.append(dom.genElement(`<th class="grid-time">${tz}</th>`));
    for (let i = 0; i < 7; i++) {
      let th = dom.genElement(`<th class="grid-day" data-date="${du.formatDate(date, 'simple')}"></th>`);
      th.append(dom.genElement(`<div>${du.dayNames[lang][i].substr(0, 3)}</div>`));
      th.append(dom.genElement(`<div>${du.formatDate(date, 'widget', lang)}</div>`));
      tr.append(th);
      date.setDate(date.getDate() + 1);
    }
    return tr;
  }

  buildCell(item) {
    let td = dom.genElement('<td>');
    if (item.noInfo) {
      td.classList.add('noinfo');
    } else {
      let div = dom.genElement(`<div class="show"></div>`);
      td.append(div);
      div.append(dom.genElement(`<span class="show-title">${item.program_name}</span>`));
      if (item.continued) div.append(dom.genElement(`<span class="show-title-suffix"> (${'es' == this.lang ? 'continuación' : 'continued'})</span>`));
      if (item.tenants) {
        for (let j = 0; j < item.tenants.length; j++) {
          td.append(dom.genElement(`<div class="show-sep"></div>`));
          div = dom.genElement(`<div class="show"></div>`);
          div.append(dom.genElement(`<span class="show-title">${item.tenants[j].program_name}</span>`));
          div.append(dom.genElement(`<span class="show-title-suffix">${' ' + item.tenants[j].time}</span>`));
          td.append(div);
        }
      }
    }
    if (item.rows > 1) td.setAttribute('rowspan', item.rows);
    if (item.cols > 1) td.setAttribute('colspan', item.cols);
    return td;
  }

  buildBodyRow(week, slot) {
    let tr = dom.genElement('<tr>'),
        hour = Math.floor(slot / 2),
        minute = slot % 2 == 0 ? '00' : '30';
    tr.append(dom.genElement(`<td class="grid-time">${du.civilian(hour)}:${minute}<br>${du.amPm(hour)}</td>`));
    for (let i = 0; i < 7; i++) {
      let item = week[i + ':' + slot];
      if (!item.rendered) {
        item.rendered = true;
        tr.append(this.buildCell(item));
      }
    }
    return tr;
  }

  weeksFrom(now, then) {
    return Math.ceil((then - now) / (7 * 24 * 60 * 60 * 1000));
  }

  splitSlot(week, day, slot) {
    let prev = week[day + ':' + (slot - 1)],
        cur = week[day + ':' + slot];
    if (!prev || !cur || prev != cur) return;
    if (prev.slot + prev.origRows > slot) {
      cur = Object.assign({}, prev);
      prev.tenants = false;
    } else {
      let i = 0; while (prev.tenants[i].slot + prev.tenants[i].origRows <= slot) i++;
      cur = Object.assign({}, prev.tenants[i]);
      cur.tenants = prev.tenants.slice(i + 1);
      prev.tenants = prev.tenants.slice(0, i + 1);
    }
    prev.rows = slot - prev.slot;
    let lastItem = (cur.tenants && cur.tenants.length > 0 ? cur.tenants[cur.tenants.length - 1] : cur);
    cur.rows = lastItem.slot + lastItem.rows - slot;
    cur.slot = slot;
    cur.continued = true;
    for (let i = 0; i < cur.rows; i++) {
      week[day + ':' + (slot + i)] = cur;
    }
  }

  moveDstOverflow(week, fromDay, fromSlot, toDay, toSlot) {
    let i = 0,
        item = week[fromDay + ':' + fromSlot];
    while (item) {
      week[toDay + ':' + (i + toSlot)] = item;
      item.slot = i + toSlot;
      week[fromDay + ':' + (i + fromSlot)] = null;
      i++;
      item = week[fromDay + ':' + (i + fromSlot)];
    }
  }

  moveDstWindow(dstChange, week) {
    let day = dstChange.slots.start.day,
        slot = dstChange.slots.start.slot;
    for (let i = 0; i < dstChange.length; i++) {
      let item = week[day + ':' + (i + slot)];
      if (!item) break;
      week['7:' + (i + slot - dstChange.length)] = item;
      item.slot = i + slot - dstChange.length;
      week[day + ':' + (i + slot)] = null;
    }
  }

  splitDstBackSlots(dstChange, week) {
    let slot = dstChange.slots.start.slot;
    if (slot < 1) return;
    for (let day = 0; day < 7; day++) {
      this.splitSlot(week, day, slot);
    }
    this.splitSlot(week, 7, slot);
    this.moveDstOverflow(week, 7, slot, dstChange.slots.start.day, slot);
  }

  splitDstFwdSlot(dstChange, week) {
    let day = dstChange.slots.start.day,
        slot = dstChange.slots.stop.slot;
    this.splitSlot(week, day, slot);
    this.moveDstOverflow(week, day, slot, day, slot + dstChange.length);
  }

  splitCrossDstBackSlot(dstChange, week) {
    let day = dstChange.slots.start.day,
        slot = dstChange.slots.start.slot;
    this.splitSlot(week, day, slot);
    this.splitSlot(week, day, slot + dstChange.length);
    this.moveDstWindow(dstChange, week);
    this.moveDstOverflow(week, day, slot + dstChange.length, day, slot);
  }

  timeZoneNames(dstChange, weekNo, dstWeekNo) {
    return [
      dstWeekNo < weekNo ? dstChange.timezoneAfter : dstChange.timezoneBefore,
      dstWeekNo > weekNo ? dstChange.timezoneBefore : dstChange.timezoneAfter
    ];
  }

  buildDstBackRows(dstChange, week) {
    let rows = [];
    for (let i = 0; i < dstChange.length; i++) {
      let tr = dom.genElement('<tr>'),
          slot = dstChange.slots.start.slot + i,
          hour = Math.floor(slot / 2),
          minute = slot % 2 == 0 ? '00' : '30',
          item = week['7:' + (slot - dstChange.length)];
      rows.push(tr);
      tr.append(dom.genElement(`<td class="grid-time">${du.civilian(hour)}:${minute}<br>${du.amPm(hour)}</td>`));
      if (0 == i && dstChange.slots.start.day > 0)
        tr.append(dom.genElement(`<td class="noinfo" rowspan="${dstChange.length}" colspan="${dstChange.slots.start.day}"></td>`));
      if (!item.rendered) {
        item.rendered = true;
        tr.append(this.buildCell(item));
      }
      if (0 == i && dstChange.slots.start.day < 6)
        tr.append(dom.genElement(`<td class="noinfo" rowspan="${dstChange.length}" colspan="${6 - dstChange.slots.start.day}"></td>`));
    }
    return rows;
  }

  buildTable(now, dstChange, stop, week) {
    let startDate = new Date(stop.getFullYear(), stop.getMonth(), stop.getDate() - 7),
        weekNo = this.weeksFrom(now, startDate),
        dstWeekNo = dstChange.diff != 0 ? this.weeksFrom(dstChange.from, startDate) : 999,
        tzNames = this.timeZoneNames(dstChange, weekNo, dstWeekNo),
        insertDstBackRows = dstChange.diff < 0 && dstWeekNo == 0,
        styleClass = 'week' + (0 == weekNo ? ' current' : ''),
        table = dom.genElement(`<table class="${styleClass}" data-sel-item="${weekNo}"></table>`);
    this.fillGaps(week);
    if (insertDstBackRows) this.splitDstBackSlots(dstChange, week);
    this.mergeHorizontally(week);
    table.append(this.buildHeaderRow(tzNames[0], this.lang, startDate));
    for (let i = 0; i < 48; i++) {
      if (insertDstBackRows && i == dstChange.slots.start.slot) table.append(this.buildDstBackRows(dstChange, week));
      table.append(this.buildBodyRow(week, i));
    }
    table.append(this.buildHeaderRow(tzNames[1], this.lang, startDate));
    return table;
  }

  sameItem(a, b) {
    if (typeof a != typeof b || typeof a.tenants != typeof b.tenants) return false;
    if (a.slot != b.slot || a.program_name != b.program_name || a.rows != b.rows || a.continued != b.continued) return false;
    if (a.tenants) {
      if (a.tenants.length != b.tenants.length) return false;
      for (let i = 0; i < a.tenants.length; i++) {
        if (a.tenants[i].program_name != b.tenants[i].program_name || a.tenants[i].time != b.tenants[i].time) return false;
      }
    }
    return true;
  }

  mergeHorizontally(week) {
    for (let day = 1; day < 7; day++) {
      let j = 0;
      while (j < 48) {
        let item = week[day + ':' + j],
            prev = week[(day - 1) + ':' + j];
        if (this.sameItem(item, prev)) {
          prev.cols++;
          for (let k = 0; k < item.rows; k++) {
            week[day + ':' + (j + k)] = prev;
          }
        }
        j += item.rows;
      }
    }
  }

  fillGaps(week) {
    for (let day = 0; day < 7; day++) {
      let slot = 0;
      while (slot < 48) {
        while (week[day + ':' + slot] && slot < 48) slot++;
        let gap = slot;
        while (!week[day + ':' + gap] && gap < 48) gap++;
        let noInfo = { slot: slot, rows: gap - slot, origRows: gap - slot, cols: 1, noInfo: true };
        while (slot < gap) {
          week[day + ':' + slot] = noInfo;
          slot++;
        }
      }
    }
  }

  getDstChange(data) {
    let dstChange = du.dstChange(new Date(data[0].start_date), new Date(data[data.length - 1].end_date));
    dstChange.slots = this.getSlots(dstChange.to, dstChange.from);
    dstChange.length = dstChange.slots.stop.slot - dstChange.slots.start.slot;
    return dstChange;
  }

  buildWeeks(gridContainer, now, data) {
    let week = {}, render = false, skip = 0,
        dstChange = this.getDstChange(data),
        dstOverflow = false;
    for (let i = 0; i < data.length; i++) {
      let item = data[i]; item.start_date = new Date(item.start_date); item.end_date = new Date(item.end_date);
    }
    // right after week boundary, returned data may be for previous week; ensure we treat it as current for DST processing
    if ('radio' == this.medium && (now < new Date(data[0].start_date) || now > new Date(data[data.length - 1].start_date)))
      now = new Date(data[Math.floor(data.length / 2)].start_date);
    for (let i = 0; i < data.length; i++) {
      let item = data[i],
          crossFwdDst = dstChange.diff > 0 && item.start_date.getTimezoneOffset() != item.end_date.getTimezoneOffset(),
          crossBackDst = dstChange.diff < 0 && !(item.start_date >= dstChange.to && item.start_date < dstChange.from) &&
            (item.end_date > dstChange.to && item.end_date < dstChange.from || item.start_date.getTimezoneOffset() != item.end_date.getTimezoneOffset()),
          slots = this.getSlots(item.start_date, item.end_date),
          midnight = slots.start.day != slots.stop.day,
          weekend = slots.start.day > slots.stop.day || i < data.length - 1 && slots.start.day > data[i + 1].start_date.getDay();
      if (slots.start.day == 0) render = true;
      if (!render && !weekend) continue; // skip programs before start of week
      item.slot = slots.start.slot;
      item.rows = item.origRows = (midnight ? 48 : slots.stop.slot) - slots.start.slot;
      item.cols = 1;
      let startInsideBackDst = (dstChange.diff < 0 && item.start_date >= dstChange.to && item.start_date < dstChange.from),
          day = startInsideBackDst || dstOverflow ? 7 : slots.start.day,
          fill = item;
      if (startInsideBackDst) {
        if (slots.stop.slot > dstChange.slots.stop.slot) dstOverflow = true;
        item.slot -= dstChange.length;
        slots.start.slot -= dstChange.length;
        slots.stop.slot -= dstChange.length;
      }
      let existing = week[day + ':' + slots.start.slot];
      if (existing) {
        if (!existing.tenants) existing.tenants = [];
        existing.tenants.push(item);
        existing.rows = (midnight ? 48 : slots.stop.slot) - existing.slot;
        item.time = '(' + du.civilian(item.start_date.getHours()) + ':' + du.fill2(item.start_date.getMinutes()) + ')';
        fill = existing;
      }
      for (let j = slots.start.slot; j < (midnight ? 48 : slots.stop.slot); j++) {
        week[day + ':' + j] = fill;
      }
      if (dstOverflow && slots.start.slot > dstChange.slots.stop.slot - dstChange.length) dstOverflow = false;
      if (weekend) {
        if (render) gridContainer.append(this.buildTable(now, dstChange, item.end_date, week));
        week = {};
      }
      if (midnight && slots.stop.slot > 0) {
        item = Object.assign({}, item);
        item.slot = 0; item.rows = item.origRows = slots.stop.slot; item.continued = true; item.rendered = false;
        for (let j = 0; j < slots.stop.slot; j++) {
          week[slots.stop.day + ':' + j] = item;
        }
      }
      if (crossFwdDst) this.splitDstFwdSlot(dstChange, week);
      else if (crossBackDst) this.splitCrossDstBackSlot(dstChange, week);
    }
  }

  fetchGrid(gridContainer) {
    let now = new Date(),
        prevSunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay()),
        url = 'radio' == this.medium ?
                `https://www.ewtn.com/Live/ewtnplayer/scheds/${this.feed}.json` :
                `/api/tv-airings/${this.feed}/${prevSunday.getTime() / 1000}`;
    fetch(url)
    .then(response => response.json())
    .then(data => this.buildWeeks(gridContainer, now, data))
    .catch(response => gridContainer.innerHTML = `<span class="error">Could not retrieve programming grid data: ${response.message}</span>`);
  }

  handleClick(e) {
    e.stopPropagation();
    var table = e.target.closest('table'),
        headers = table.getElementsByTagName('th'),
        targetHeader, i;
    for (i = 1; i < 8; i++) {
      if (dom.offset(headers[i]).left >= e.clientX) break;
      targetHeader = i;
    }
    if (targetHeader) {
      let base = 'es' == this.lnag ? `/es/${this.medium}/horario` : `/${this.medium}/schedule`;
      window.open(`${base}/${this.satelliteId}/${headers[targetHeader].dataset.date}`);
    }
  }

  connect() {
    let gridContainer = this.element;
    this.feed = gridContainer.dataset.feed;
    this.medium = this.feed.indexOf('radio') >= 0 ? 'radio' : 'tv';
    this.satelliteId = gridContainer.dataset.sat;
    this.lang = document.getElementsByTagName('html')[0].lang;
    gridContainer.onclick = e => this.handleClick(e);
    this.fetchGrid(gridContainer);
  }
}
